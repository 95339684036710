import { graphql } from "gatsby"
import * as React from "react"

import Layout from '../components/layout'
import Product from "../components/item"
import { FaFilm } from 'react-icons/fa';

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <div className="w-full py-2 md:py-4 px-2 md:px-4 mb-4">
        <div className="w-full flex space-x-2 text-2xl font-bold items-center mb-2"><FaFilm className="text-red-600"/><span>TV Series</span></div>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
          {
            data.allNodeTvSeries.edges.map(node => (
              <Product 
              key={node.node.id}
              slug={node.node.path.alias}
              title={node.node.title}
              info={node.node.field_info}
              poster={node.node.relationships.field_image.localFile.url}
              category={node.node.relationships.field_tags.map(tag => (tag.name))}></Product>
            ))
          }
        </div>
      </div>
      <div className="w-full py-2 md:py-4 px-2 md:px-4 mb-4">
        <div className="w-full flex space-x-2 text-2xl font-bold items-center mb-2"><FaFilm className="text-red-600"/><span>Documentaries</span></div>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
          {
            data.allNodeDocumentaries.edges.map(node => (
              <Product 
              key={node.node.id}
              slug={node.node.path.alias}
              title={node.node.title}
              info={node.node.field_info}
              poster={node.node.relationships.field_image.localFile.url}
              category={node.node.relationships.field_tags.map(tag => (tag.name))}></Product>

            ))
          }
        </div>
      </div>

      <div className="w-full py-2 md:py-4 px-2 md:px-4 mb-4">
        <div className="w-full flex space-x-2 text-2xl font-bold items-center mb-2"><FaFilm className="text-red-600"/><span>For Kids</span></div>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
          {
            data.allNodeForKids.edges.map(node => (
              <Product 
              key={node.node.id}
              slug={node.node.path.alias}
              title={node.node.title}
              info={node.node.field_info}
              poster={node.node.relationships.field_image.localFile.url}
              category={node.node.relationships.field_tags.map(tag => (tag.name))}></Product>

            ))
          }
        </div>
      </div>

      <div className="w-full py-2 md:py-4 px-2 md:px-4 mb-4">
        <div className="w-full flex space-x-2 text-2xl font-bold items-center mb-2"><FaFilm className="text-red-600"/><span>Other Movies</span></div>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
          {
            data.allNodeOther.edges.map(node => (
              <Product 
              key={node.node.id}
              slug={node.node.path.alias}
              title={node.node.title}
              info={node.node.field_info}
              poster={node.node.relationships.field_image.localFile.url}
              category={node.node.relationships.field_tags.map(tag => (tag.name))}></Product>

            ))
          }
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage


export const query = graphql`
  query {
    allNodeTvSeries(filter: {promote: {eq: true}}) {
      edges {
          node {
              id
              title
              path {
                  alias
              }
              field_info
              relationships {
                field_image {
                  localFile {
                    url
                  }
                }
                field_tags {
                  name
                }
              }
          }
      }
    }
    allNodeDocumentaries(filter: {promote: {eq: true}}) {
      edges {
          node {
              id
              title
              path {
                  alias
              }
              field_info
              relationships {
                field_image {
                  localFile {
                    url
                  }
                }
                field_tags {
                  name
                }
              }
          }
      }
    }
    allNodeForKids(filter: {promote: {eq: true}}) {
      edges {
          node {
              id
              title
              path {
                  alias
              }
              field_info
              relationships {
                field_image {
                  localFile {
                    url
                  }
                }
                field_tags {
                  name
                }
              }
          }
      }
    }
    allNodeOther(filter: {promote: {eq: true}}) {
      edges {
          node {
              id
              title
              path {
                  alias
              }
              field_info
              relationships {
                field_image {
                  localFile {
                    url
                  }
                }
                field_tags {
                  name
                }
              }
          }
      }
    }
  }
`
